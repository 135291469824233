<template>
  <b-modal
    :id="`modal-deleteOperation${modalid}`"
    centered
    hide-footer
    hide-header
    header-text-variant="light"
    hide-header-close
    body-bg-variant="danger"
    body-text-variant="white"
    size="delete"
  >
    <b-container :disabled="loading">
      <div class="d-flex flex-column">
        <div class="title">Emin misiniz?</div>
        <div class="desc mt-3">
          İşlemi tamamlamak için aşağıdaki işlemi çözün ve onayla butonuna basın
        </div>
        <div class="question mt-3">
          {{ `${form.number1} + ${form.number2} toplamı nedir?` }}

          <Input
            placeholder="Cevabı yazınız"
            id="answer"
            v-model="form.answer"
            :state="validateState('answer')"
            @formReset="formReset"
            class="mt-2"
            @click-enter="continueOperation()"
          />
        </div>
        <div
          class="buttons mt-3 d-flex align-items-center justify-content-between"
        >
          <b-button
            class="rounded-sm text-danger w-50 btn-cancel-custom"
            @click="onCloseCompleteModal()"
            >Vazgeç</b-button
          >
          <b-button
            class="rounded-sm text-sucess w-50 btn-confirm-custom"
            @click="continueOperation()"
            >Onayla</b-button
          >
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'DeleteOperation',
  mixins: [validationMixin],
  props: {
    url: String,
    params: Array | String,
    modalid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      form: {
        number1: '0',
        number2: '0',
        answer: '0',
      },
    };
  },
  validations: {
    form: {
      answer: {
        required,
        sameAsResult: sameAs(function () {
          return (
            parseInt(this.form.number1) + parseInt(this.form.number2)
          ).toString();
        }),
      },
    },
  },
  created() {
    this.getRandomNumber();
  },
  methods: {
    getRandomNumber() {
      this.form.number1 = Math.ceil(Math.random() * 9);
      this.form.number2 = Math.ceil(Math.random() * 9);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    continueOperation() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.loading = true;
      this.$dbFunctions
        .delete(this.url, this.params)
        .then((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.$emit('updateList');

          this.onCloseCompleteModal();
        })
        .catch((res) => {
          this.$dbFunctions.setResponse(this, res);
          this.$emit('errorUpdate');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onCloseCompleteModal() {
      this.$bvModal.hide('modal-deleteOperation' + this.modalid);
      setTimeout(() => {
        this.getRandomNumber();
      }, 500);
    },
    formReset(payload) {
      if (payload) this.$v.$reset();
    },
  },
  watch: {
    url() {
      this.getRandomNumber();
    },
  },
};
</script>

<style lang="scss">
#modal-deleteOperation {
  .modal-body {
    border-radius: 12px;
    .container {
      padding: 0;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 25px;
        /* identical to box height */

        color: #ffffff;
      }
      .desc {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */

        color: #ffffff;
      }
      .question {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        color: #e9e9e9;
      }
      .buttons {
        .btn-cancel-custom {
          background: #f64e60 !important;
          border-radius: 12px !important;
          font-weight: 600 !important;
          font-size: 12px !important;
          line-height: 18px !important;
          color: #ffffff !important;
          margin-right: 5px !important;
          &:hover {
            opacity: 0.8;
          }
        }
        .btn-confirm-custom {
          background: #ffffff !important;
          border-radius: 12px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 12px !important;
          line-height: 18px !important;
          color: #f64e60 !important;
          margin-left: 5px !important;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
